import request from '@/utils/request'

/**
 * 开户
 * return promise
 */
export const accountOpen = data => {
  return request({
    url: '/admin/account/open',
    method: 'POST',
    data
  })
}

/**
 * 查询小程序用户
 */
export const accountList = (data) => {
  return request({
    url: `/admin/account/list?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * 更新支付信息
 * return promise
 */
export const accountUpdatePay = data => {
  return request({
    url: '/admin/account/update/pay',
    method: 'POST',
    data
  })
}

/**
 * 更新返利
 * return promise
 */
export const accountUpdateRebate = data => {
  return request({
    url: '/admin/account/update/rebate',
    method: 'POST',
    data
  })
}

/**
 * 获取用户支付信息
 * return promise
 */
export const accountInfoPay = data => {
  return request({
    url: '/admin/account/pay/info',
    method: 'POST',
    data
  })
}

/**
 * 打款
 */
export const settlePay = data => {
  return request({
    url: '/admin/settle/pay',
    method: 'POST',
    data
  })
}

/**
 * 清算列表
 */
export const settleList = data => {
  return request({
    url: `/admin/settle/list?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

<template>
  <el-drawer
    v-model="drawerVisible"
    :destroy-on-close="true"
    @close="drawerClose"
    size="450px"
    :title="`${drawerProps.title}账号`"
  >
    <el-form
      ref="ruleFormRef"
      label-width="100px"
      label-suffix=" :"
      :rules="rules"
      :model="drawerProps.row"
    >
      <el-form-item label="用户名" prop="username">
        <el-input
          v-model="drawerProps.row.username"
          placeholder="请填写用户名"
          :disabled="drawerProps.isView"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="drawerProps.row.password"
          type="password"
          placeholder="请填写密码"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input
          v-model="drawerProps.row.confirmPassword"
          type="password"
          placeholder="请再次填写密码"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="返利" prop="rebate">
        <el-input
          v-model="drawerProps.row.rebate"
          placeholder="请填写返利"
          :disabled="drawerProps.isView"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="drawerVisible = false">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </template>
  </el-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { accountOpen } from '@/api/account'
import { ElMessage } from 'element-plus'

const ruleFormRef = ref()
const drawerVisible = ref(false)
const drawerProps = ref({
  isView: false,
  title: '',
  row: {
    username: '',
    password: '',
    confirmPassword: '',
    rebate: ''
  }
})

const drawerClose = () => {
  drawerVisible.value = false
  drawerProps.value = {
    isView: false,
    title: '',
    row: {
      username: '',
      password: '',
      confirmPassword: '',
      rebate: ''
    }
  }
}

const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else {
    if (drawerProps.value.row.confirmPassword !== '') {
      if (!ruleFormRef.value) return
      ruleFormRef.value.validateField('confirmPassword', () => null)
    }
    callback()
  }
}
const validatePass2 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== drawerProps.value.row.password) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}

const rules = reactive({
  username: [{ required: true, message: '请填写用户名' }],
  password: [{ validator: validatePass, required: true, trigger: 'blur' }],
  confirmPassword: [
    { validator: validatePass2, required: true, trigger: 'blur' }
  ],
  rebate: [{ required: true, message: '请填写返利' }]
})

// 接收父组件传过来的参数
const acceptParams = async params => {
  console.log('接收参数12', params)
  drawerProps.value = params
  drawerVisible.value = true
}

// 提交数据（新增/编辑）
const handleSubmit = () => {
  ruleFormRef.value.validate(async valid => {
    if (!valid) return
    try {
      const params = {
        username: drawerProps.value.row.username,
        password: drawerProps.value.row.password,
        rebate: drawerProps.value.row.rebate
      }
      await accountOpen(params)
      ElMessage({
        message: '添加账号成功',
        type: 'success'
      })
      drawerVisible.value = false
    } catch (error) {
      ElMessage({
        message: '添加账号失败',
        type: 'danger'
      })
      drawerVisible.value = false
    }
  })
}

defineExpose({
  acceptParams
})
</script>

<template>
  <el-drawer
    v-model="drawerVisible"
    :destroy-on-close="true"
    @close="drawerClose"
    size="500px"
    :title="`${drawerProps.title}`"
  >
    <el-form
      ref="ruleFormRef"
      label-width="130px"
      label-suffix=" :"
      :rules="rules2"
      :model="drawerProps.row"
    >
      <el-form-item label="用户名" prop="username">
        <el-input
          v-model="drawerProps.row.username"
          placeholder="请填写用户名"
          :disabled="drawerProps.isView"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="返利" prop="rebate">
        <el-input
          v-model="drawerProps.row.rebate"
          placeholder="请填写返利"
          :disabled="drawerProps.isView"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付平台商户ID" prop="payMchId">
        <el-input
          v-model="drawerProps.row.payMchId"
          placeholder="请填写支付平台商户ID"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付应用ID" prop="payAppId">
        <el-input
          v-model="drawerProps.row.payAppId"
          placeholder="请填写支付应用ID"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付密钥" prop="payAppSecret">
        <el-input
          v-model="drawerProps.row.payAppSecret"
          placeholder="请填写支付密钥"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="微信公众号ID" prop="wxAppId">
        <el-input
          v-model="drawerProps.row.wxAppId"
          placeholder="请填写微信公众号ID"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="微信公众号密钥" prop="wxAppSecret">
        <el-input
          v-model="drawerProps.row.wxAppSecret"
          placeholder="请填写微信公众号密钥"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付宝appId" prop="aliAppId">
        <el-input
          v-model="drawerProps.row.aliAppId"
          placeholder="请填写支付宝appId"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付宝RSA私钥" prop="aliAppPrivate">
        <el-input
          v-model="drawerProps.row.aliAppPrivate"
          type="textarea"
          :rows="5"
          placeholder="请填写支付宝RSA私钥"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="支付宝RSA公钥" prop="aliAppPublic">
        <el-input
          v-model="drawerProps.row.aliAppPublic"
          type="textarea"
          :rows="5"
          placeholder="请填写支付宝RSA公钥"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="drawerVisible = false">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </template>
  </el-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { accountInfoPay, accountUpdatePay } from '@/api/account'
import { ElMessage } from 'element-plus'

const ruleFormRef = ref()
const drawerVisible = ref(false)
const accountInfo = ref(null)
const drawerProps = ref({
  isView: false,
  title: '',
  row: {
    username: '',
    password: '',
    confirmPassword: '',
    rebate: ''
  }
})

const drawerClose = () => {
  drawerVisible.value = false
  accountInfo.value = null
  drawerProps.value = {
    isView: false,
    title: '',
    row: {
      username: '',
      password: '',
      confirmPassword: '',
      rebate: ''
    }
  }
}

const rules2 = reactive({
  payMchId: [{ required: true, message: '请填写支付平台商户ID' }],
  payAppId: [{ required: true, message: '请填写支付应用ID' }],
  payAppSecret: [{ required: true, message: '请填写支付密钥' }],
  wxAppId: [{ required: true, message: '请填写微信公众号id' }],
  wxAppSecret: [{ required: true, message: '请填写微信公众号密钥' }],
  aliAppId: [{ required: true, message: '请填写支付宝appId' }],
  aliAppPrivate: [{ required: true, message: '请填写支付宝RSA私钥' }],
  aliAppPublic: [{ required: true, message: '请填写支付宝RSA公钥' }]
})

// 接收父组件传过来的参数
const acceptParams = async params => {
  drawerProps.value = params
  drawerVisible.value = true
  const res = await accountInfoPay({ userId: params.row.id })
  accountInfo.value = res
  const row = {
    ...params.row,
    ...accountInfo.value
  }
  drawerProps.value = Object.assign({}, drawerProps.value, { row })
}

// 提交数据（新增/编辑）
const handleSubmit = () => {
  ruleFormRef.value.validate(async valid => {
    if (!valid) return
    try {
      const params = {
        userId: drawerProps.value.row.id,
        payMchId: drawerProps.value.row.payMchId,
        payAppId: drawerProps.value.row.payAppId,
        payAppSecret: drawerProps.value.row.payAppSecret,
        wxAppId: drawerProps.value.row.wxAppId,
        wxAppSecret: drawerProps.value.row.wxAppSecret,
        aliAppId: drawerProps.value.row.aliAppId,
        aliAppPrivate: drawerProps.value.row.aliAppPrivate,
        aliAppPublic: drawerProps.value.row.aliAppPublic
      }
      await accountUpdatePay(params)
      ElMessage({
        message: '修改支付信息成功',
        type: 'success'
      })
      drawerVisible.value = false
    } catch (error) {
      ElMessage({
        message: '修改支付信息失败',
        type: 'danger'
      })
      drawerVisible.value = false
    }
  })
}

defineExpose({
  acceptParams
})
</script>

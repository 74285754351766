<template>
  <div class="user-manage-container">
    <el-card class="header table-search" v-if="false">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="用户名">
          <el-input
            v-model="formInline.user"
            placeholder="请输入用户名"
            clearable
          />
        </el-form-item>
        <el-form-item label="更新时间">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="请选择更新时间"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="onSubmit"
            >查询</el-button
          >
          <el-button :icon="Delete" @click="reset"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <div class="filter-container">
        <el-button
          type="primary"
          :icon="CirclePlus"
          @click="openDrawer('新增')"
        >
          新增账号
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中..."
        :data="tableData"
        border
        style="width: 100%"
        header-cell-class-name="table-header"
      >
        <el-table-column label="序号" type="index" align="center" width="70" />
        <el-table-column prop="username" label="用户名" align="center">
        </el-table-column>
        <el-table-column prop="rebate" label="返利" align="center">
        </el-table-column>
        <el-table-column
          prop="account"
          label="支付账户"
          align="center"
        ></el-table-column>
        <!-- 角色 -->
        <el-table-column label="创建时间" align="center">
          <template #default="{ row }">
            {{ row.createTime ? $filters.dateFilter(row.createTime) : '--' }}
          </template>
        </el-table-column>
        <!-- 时间 -->
        <el-table-column label="更新时间" align="center">
          <template #default="{ row }">
            {{ row.updateTime ? $filters.dateFilter(row.updateTime) : '--' }}
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed="right" width="300" align="center">
          <template #default="{row}">
            <el-popover
              v-model:visible="row.popoverVisible"
              :width="300"
              placement="top-start"
              trigger="click"
              popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
            >
              <template #reference>
                <el-button type="primary" link :icon="Edit">更新返利</el-button>
              </template>
              <template #default>
                <div
                  class="demo-rich-conent"
                  style="display: flex; gap: 16px; flex-direction: column"
                >
                  <p style="margin: 0; font-weight: 500">更新返利</p>
                  <el-input
                    v-model="row.rebate"
                    placeholder="请填写返利"
                    clearable
                  />
                  <el-button type="primary" @click="updateRebate(row)"
                    >确定</el-button
                  >
                </div>
              </template>
            </el-popover>
            <el-button
              type="primary"
              link
              :icon="Refresh"
              @click="openEditDrawer('更新支付信息', row)"
              >更新支付信息</el-button
            >
            <el-button
              type="primary"
              link
              :icon="Position"
              @click="openPayDialog(row)"
              >打款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        :background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </el-card>
    <AccountDrawer ref="accountDrawer" />
    <EditAccountInfo ref="editAccountInfoDrawer" />
    <el-dialog
      v-model="centerDialogVisible"
      title="打款"
      width="500"
      align-center
      @close="dialogClose"
    >
      <el-input v-model="settlePayNum" placeholder="请输入打款金额" />
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handlePay">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import {
  Delete,
  Edit,
  Search,
  CirclePlus,
  Refresh,
  Position
} from '@element-plus/icons-vue'
import { accountList, accountUpdateRebate, settlePay } from '@/api/account'

import { ElMessage } from 'element-plus'
import AccountDrawer from './component/AccountDrawer.vue'
import EditAccountInfo from './component/EditAccountInfo.vue'

const formInline = reactive({
  user: '',
  region: '',
  date: ''
})
// 数据相关
const centerDialogVisible = ref(false)
const loading = ref(false)
const tableData = ref([])
const page = reactive({
  pageIndex: 1,
  pageSize: 10,
  total: 0
})

const currentData = ref(null)
const settlePayNum = ref('')
const openPayDialog = row => {
  currentData.value = row
  centerDialogVisible.value = true
}
// 打款
const handlePay = async () => {
  if (!currentData.value) return
  await settlePay({
    username: currentData.value.username,
    price: settlePayNum.value
  })
  ElMessage.success('打款成功')
  centerDialogVisible.value = false
}

const dialogClose = () => {
  currentData.value = null
  centerDialogVisible.value = false
  settlePayNum.value = ''
}

// 打开 drawer(新增、查看、编辑)
const accountDrawer = ref(null)
const openDrawer = (title, row) => {
  const params = {
    title,
    isView: title !== '新增',
    row: { ...row }
  }
  accountDrawer.value.acceptParams(params)
}

const editAccountInfoDrawer = ref(null)
const openEditDrawer = (title, row) => {
  const params = {
    title,
    isView: true,
    row: { ...row }
  }
  editAccountInfoDrawer.value.acceptParams(params)
}

const onSubmit = () => {
  console.log('submit!')
}

const reset = () => {
  console.log('reset!')
}

// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const { pageIndex, pageSize } = page
  const res = await accountList({
    pageIndex,
    pageSize
  })
  loading.value = false
  tableData.value = res.datas
  page.total = res.records
}
getListData()
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  page.pageSize = currentSize
  getListData()
}
/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.pageIndex = currentPage
  getListData()
}

const updateRebate = async row => {
  row.popoverVisible = false
  await accountUpdateRebate({
    userId: row.id,
    rebate: row.rebate
  })
  getListData()
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.user-manage-container {
  .header {
    @include searchForm;
  }

  .filter-container {
    .el-button {
      margin-bottom: 15px;
    }
  }

  :deep(.table-header) {
    @include tableThead;
  }
  .pagination {
    @include pagination;
  }
}
</style>
